import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import {
  WhiteSection,
  FullPageOrangeSection,
  BlurbWrapper,
  BlurbText,
  GreenBreak,
  OrangeBreak,
  PageBreakText,
  TitleText,
  SubTitleText,
  TextImageWrapper,
  BlurbLink,
} from "../components/styled-components";
import Hero from "../components/hero";
import HexTransition from "../components/hex-transition";
import QuickLinks from "../components/quick-links";
import MainLayout from "../components/main-layout";
import LargeIcon from "../components/large-icon";
import ImageWrapper from "../components/image-wrapper";

const pathArray = [
  {
    path: "/gosolar#landowner",
    title: "AGRICULTURAL",
  },
  {
    path: "/gosolar#business",
    title: "COMMERCIAL",
  },
];

const GoSolar = ({ data }) => {
  const commImgOne = data.allContentfulImage.nodes.find(
    (node) => node.slug === "gosolar-comm-1"
  );
  const commImgTwo = data.allContentfulImage.nodes.find(
    (node) => node.slug === "gosolar-comm-2"
  );
  return (
    <MainLayout>
      <Helmet>
        <title>BOW - Go Solar</title>
        <meta
          name="description"
          content="Renewable solar energy is an effective solution to both rising operational costs and declining planet sustainability. Our targeted approach to your specific needs helps avoid costly mistakes, because we’re all on the same team."
        />
      </Helmet>
      <FullPageOrangeSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <Hero
            fluid={data.contentfulHeroImage.heroImage.fluid}
            alt={data.contentfulHeroImage.alt}
            type={"WtoO"}
          />
          <QuickLinks pathArray={pathArray} />
          <HexTransition type={"GtoW"} />
          <br />
          <TitleText color="green">GO SOLAR</TitleText>
        </div>
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            Renewable solar energy is an effective solution to both rising
            operational costs and declining planet sustainability. Our targeted
            approach to your specific needs helps avoid costly mistakes, because
            we’re all on the same team.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageOrangeSection>
      <WhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <GreenBreak height={"10vh"} minHeight={"100px"} id="landowner">
            <PageBreakText>AGRICULTURAL</PageBreakText>
          </GreenBreak>
          <HexTransition type={"GtoW"} />
          <br />
        </div>
        <br />
        <br />
        <TextImageWrapper>
          <div
            data-sal="slide-left"
            css={css`
              width: 25%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 10px;
                width: 75%;
              }
            `}
          >
            <LargeIcon type="farmSolar" />
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 65%;
              display: flex;
              flex-direction: column;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <SubTitleText color="green">FARMING SOLAR</SubTitleText>
            <BlurbText color="#1a4428" margin={true}>
              As you may know, over-farming land can actually change the soil’s
              nutrients, leading to lower quality – and quantity – of crops.
              Converting some or all of your land to solar operations gives the
              soil a chance to breathe and regenerate while still generating
              income. As the owner of the land, solar can generate three- to
              four-times more profit than renting.
            </BlurbText>
          </div>
        </TextImageWrapper>
        <br />
        <TextImageWrapper reverse={true}>
          <div
            data-sal="slide-left"
            css={css`
              width: 65%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <SubTitleText color="green">LAND LEGACY</SubTitleText>
            <BlurbText color="#1a4428" margin={true}>
              Above all else, BOW Renewables cares about the land, and the
              legacy it carries. We understand the fact that your land may have
              been in your family for centuries. We are committed to treating
              the land respectfully, with little if any disruption to the land
              itself. Once the solar lease is complete and the assets are
              removed, the soil is refreshed, and completely usable. So while
              you’re investing in the future, you’re also protecting the legacy
              of the land.
            </BlurbText>
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 25%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 10px;
                width: 75%;
              }
            `}
          >
            <LargeIcon type="landLegacy" />
          </div>
        </TextImageWrapper>
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            to start protecting the life and land of your property.
          </BlurbText>
        </BlurbWrapper>
        <br />
        <br />
      </WhiteSection>
      <WhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <OrangeBreak height={"10vh"} minHeight={"100px"} id="business">
            <PageBreakText color="green">COMMERCIAL</PageBreakText>
          </OrangeBreak>
          <HexTransition type={"OtoW"} />
          <br />
        </div>
        <br />
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            Every business has an operational budget, and any measures taken to
            reduce that budget means more profits and revenue-generating
            opportunities. BOW Renewables’ consultants allow you to improve your
            bottom line with a constant flow of positive revenue in a remarkably
            short amount of time.
          </BlurbText>
        </BlurbWrapper>
        <br />
        <TextImageWrapper reverse={true}>
          <div
            data-sal="slide-left"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              Today’s business climate demands a laser-focused plan for
              cost-effective and socially responsible operations. Besides
              creating jobs and revenue, converting your Commercial or
              Industrial space to renewable solar energy can turn watts into
              dollars with the flip of a switch.
            </BlurbText>
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={commImgOne.image.fluid} alt={commImgOne.alt} />
          </div>
        </TextImageWrapper>
        <br />
        <TextImageWrapper>
          <div
            data-sal="slide-left"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={commImgTwo.image.fluid} alt={commImgTwo.alt} />
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              Our expert energy consultants have worked with tens of thousands
              of watts, resulting in hundreds of thousands of dollars in found
              income for our clients. This win-win solution allows your business
              to see a dramatic reduction in overhead costs while generating
              income, all with a minimal carbon footprint.
            </BlurbText>
          </div>
        </TextImageWrapper>
        <br />
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            When you work with BOW Renewables, we understand we are an extension
            of your existing team, and pride ourselves on working with honesty,
            integrity and full transparency. There’s good reason why we’re
            hired, re-hired, and hired again for{" "}
            <BlurbLink to="/projects" color="#215732" stripHash>
              hundreds of clients.
            </BlurbLink>{" "}
            Simply put, you want to reduce the spend on your operational budget,
            we’re experts on how to do just that.
          </BlurbText>
          <br />
          <BlurbText color="#1a4428" margin={true}>
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            to start lowering expenses and generating revenue.
          </BlurbText>
        </BlurbWrapper>
        <br />
        <br />
      </WhiteSection>
    </MainLayout>
  );
};

export default GoSolar;

export const query = graphql`
  query GoSolarQuery {
    contentfulHeroImage(slug: { eq: "goSolarHero" }) {
      heroImage {
        fluid(maxWidth: 3080, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroAlt
    }
    allContentfulImage(filter: { tags: { in: "gosolar" } }) {
      nodes {
        alt
        slug
        image {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
